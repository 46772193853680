<template>
  <Layout v-if="!['login'].includes($route.name)">

    <a-layout-content :class="[
      layoutStore.theme == 'light' ? 'bg-white' : 'light-secondary'
    ]" class="page-content-section"
      :style="{ padding: '24px', minHeight: '280px' }">
      <!-- :style="{ padding: '24px 16px', minHeight: '850px' }" -->
      <router-view></router-view>
    </a-layout-content>
  </Layout>
  <a-layout-content :class="[
    layoutStore.theme == 'light' ? '' : 'light-secondary',
    $route.name == 'login' ? 'page-content-section-1' : '',
  ]" class="page-content-section" v-else>
    <!-- :style="{ padding: '24px 16px', minHeight: '850px' }" -->
    <router-view></router-view>
  </a-layout-content>
</template>

<script setup>
import Layout from "./components/layout/layout.vue";
import { watch, onMounted } from 'vue';
import { useLayoutStore } from "@/helpers/api/layoutStore";
const layoutStore = useLayoutStore();

watch(layoutStore.theme, (newTheme) => {
  if (newTheme == "dark") {
    document.body.classList.add("dark-mode-body");
  } else {
    document.body.classList.remove("dark-mode-body");
  }
});

onMounted(() => {
  if (layoutStore.theme == "dark") {
    document.body.classList.add("dark-mode-body");
  } else {
    document.body.classList.remove("dark-mode-body");
  }
});
</script>
