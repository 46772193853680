export const defaultData = [
  {
    id: 1,
    name: "Demo",
  },
];

export const defaultUser = {
  id: 1,
  email: "admin@holaprime.com",
  password: "Hola@prime!",
  name: "Admin",
};
