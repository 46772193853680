<template>
    <a-layout>
        <!-- left sidebar start  -->
        <a-layout-sider id="leftSider" :class="layoutStore.theme == 'light' ? 'bg-light' : 'layout-dark-bg'"
            v-model:collapsed="collapsed" :theme="layoutStore.theme" :trigger="null" collapsible>

            <!-- logo start  -->
            <div class="logo d-flex justify-content-center align-items-center"
                :class="layoutStore.theme == 'light' ? 'bg-light' : 'layout-dark-bg'">
                <div v-if="collapsed">
                    <img src="@/assets/img/small-logo.png" class="mx-auto d-block small-logo" alt="logo_small" />
                </div>
                <div v-else class="d-flex justify-content-center align-items-center">
                    <div v-if="layoutStore.theme == 'light'">
                        <img src="@/assets/img/logo.png" alt="logo" class="big-logo" />
                    </div>
                    <div v-else>
                        <img src="@/assets/img/logo.png" alt="dark-mode-logo" class="big-logo" />
                    </div>
                </div>
            </div>
            <!-- logo end  -->

            <div class="overflow-y-auto" style="height: calc(100vh - 60px);">
                <!-- main menu start  -->
                <a-menu class="menu-list" v-model="selectedKeys" :theme="layoutStore.theme" mode="inline"
                    :class="layoutStore.theme == 'light' ? 'bg-light' : 'layout-dark-bg'" :open-keys="openKeys"
                    @openChange="onOpenChange">
                    <div v-for="menu in menulist" :key="menu">
                        <a-menu-item v-if="!menu.subMenuTitle" :key="menu.keyIndex"
                            :class="{ 'ant-menu-item-selected': $route.name == menu.routerName }">
                            <template #title>{{ menu?.name }}</template>
                            <router-link :to="menu.link" class="d-flex align-items-center h-100"
                                :class="collapsed ? 'justify-content-center' : ''">
                                <vue-feather :type="menu.icon" />
                                <span class="title">{{ menu?.name }}</span>
                            </router-link>
                        </a-menu-item>
                        <div v-if="menu.subMenuTitle">
                            <a-sub-menu :key="menu.submenuKey">
                                <template #icon>
                                    <vue-feather :type="menu.subMenuIcon" />
                                </template>
                                <template #title>{{ menu.subMenuTitle }}</template>
                                <a-menu-item :key="submenu?.keyIndex" v-for="submenu in menu.subMenuList"
                                    :class="{ 'ant-menu-item-selected': $route.name == menu.routerName }">
                                    <span @click="$router.push(submenu?.link)"
                                        class="subMenu d-flex align-items-center">
                                        <vue-feather :type="submenu?.icon" />
                                        <span class="title">{{
                                            submenu?.name ? submenu?.name : ""
                                        }}</span>
                                    </span>
                                </a-menu-item>
                            </a-sub-menu>
                        </div>
                    </div>
                </a-menu>
                <!-- main menu end  -->
            </div>
        </a-layout-sider>
        <!-- left sidebar end  -->

        <a-layout>
            <!-- header start  -->
            <headerView :collapsed="collapsed" @update:collapsed="updateCollapsed" />
            <!-- header end  -->

            <!-- page view content start -->
            <slot />
            <!-- page view content end -->
        </a-layout>
    </a-layout>
</template>

<script setup>
import { ref } from "vue";
import { menulist } from "@/helpers/menu.js";
import { useLayoutStore } from "@/helpers/api/layoutStore";
import headerView from "./headerView.vue";

const layoutStore = useLayoutStore();

const collapsed = ref(false);
const selectedKeys = ref(["1"]);
const openKeys = ref([]);

function onOpenChange(openKeys) {
    console.log(openKeys);
}
function updateCollapsed(newCollapsed) {
    collapsed.value = newCollapsed;
}
</script>