import { defineStore } from "pinia";
import { defaultUser } from "@/database/data";
import axios from "axios";
import errorHandler from "@/helpers/errorHandler";
const user = JSON.parse(localStorage.getItem("user"));
const API_URL = process.env.VUE_APP_ROOT_API;

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      user: user ? user : [],
    };
  },
  actions: {
    // login
    async login(data) {
      this.logout();

      this.user = {
        ...defaultUser,
        ...data,
      };
      localStorage.setItem("user", JSON.stringify(this.user));
      return this.user;

      // return await axios
      // .post(API_URL + "login", data)
      // .then((response) => {
      //   if (response.data) {
      //       localStorage.setItem("user", JSON.stringify(response.data));
      //   }
      //   return response.data;
      // })
      // .catch((error) => {
      //   errorHandler(error);
      // });
    },

    // register
    async register(data) {
      return await axios
      .post(API_URL + "register", data)
      .then((response) => {
        if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((error) => {
        errorHandler(error);
      });
    },

    // logout
    async logout() {
        localStorage.clear();
        localStorage.clear();
    }
  },
});