import { defineStore } from "pinia";
export const useLayoutStore = defineStore("layout", {
  state: () => {
    return {
      theme: localStorage.theme ? localStorage.getItem("theme") : "light",
    };
  },
  actions: {
    // set theme
    async changeTheme(theme) {
      localStorage.setItem("theme", theme);
      this.theme = theme;
    },
  },
});